// 返回 类似 2020/01/01 格式的字符串 ok
const formatDate = function(year, month, day) {
	month < 10 && (month = "0" + month);
	day < 10 && (day = "0" + day);
	return year + "-" + month + "-" + day;
};

//获取完整日期
const returnData = function(d) {
	var year = d.getFullYear();
	var month = d.getMonth() + 1;
	var day = d.getDate();
	var m = month >= 10 ? month : "0" + month;
	var daa = day >= 10 ? day : "0" + day;
	return year + "-" + m + "-" + daa;
};

// 获取本月第一天和最后一天
const getFirstAndLast = function(d, type = null) {
	var obj = {},
		arr = [],
		now,
		nowMonth,
		nowYear;
	if (d) {
		now = new Date(d); //当前日期
		nowMonth = now.getMonth(); //当前月
		nowYear = now.getFullYear(); //当前年
	} else {
		now = new Date(); //当前日期
		nowMonth = now.getMonth(); //当前月
		nowYear = now.getFullYear(); //当前年
	}
	//本月的开始时间
	var monthStartDate = new Date(nowYear, nowMonth, 1);
	var startY = monthStartDate.getFullYear();
	var startM = monthStartDate.getMonth() + 1;
	var startD = monthStartDate.getDate();
	var SAllTime = startY + "-" + startM + "-" + startD + " 00:00:00";
	if (type) {
		var firstTime = startY + "-" + startM + "-" + startD;
	}
	//本月的结束时间
	var monthEndDate = new Date(nowYear, nowMonth + 1, 0);
	var endY = monthEndDate.getFullYear();
	var endM = monthEndDate.getMonth() + 1;
	var endD = monthEndDate.getDate();
	var EAllTime = endY + "-" + endM + "-" + (endD-1) + " 23:59:59";
	if (type) {
		var EndTime = endY + "-" + endM + "-" + endD;
	}
	var dStart = new Date(SAllTime);
	var dEnd = new Date(EAllTime);
	// console.log(monthStartDate,monthEndDate)
	if (type) {
		arr = [{
				data: firstTime
			},
			{
				data: EndTime
			}
		];
	} else {
		obj = {
			start: dStart,
			end: dEnd
		};
	}

	var data1 = new Date(obj.start);
	var data2 = new Date(obj.end);
	if (type) {
		return arr;
	} else {
		return {
			startTime: data1.getTime() / 1000,
			endTime: data2.getTime() / 1000
		};
	}
};
// 判断是否为今天
const isToday = function(str) {
	var d = new Date();
	var y = d.getFullYear(); // 年
	var m = d.getMonth() + 1; // 月份从0开始的
	var d = d.getDate(); //日
	let ma = m >= 10 ? m : "0" + m;
	let da = d >= 10 ? d : "0" + d;
	return str == y + "-" + ma + "-" + da;
};
export {
	formatDate,
	returnData,
	getFirstAndLast,
	isToday
}
